.Error {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 8rem 0 4rem;
  text-align: center;
}
.Error__title {
  margin: 0;
  font-size: 11rem;
  line-height: 1;
}
.Error__subtitle {
  display: block;
  margin: 2rem 0;
  font-size: 2rem;
}
.Error__body {
  margin: 0;

  a {
    font-style: italic;
    text-decoration: none;
    color: #284177;
  }
}
